import React from "react";
import "../styles/creative.css";
import "../styles/global.css";
import LegalLayout from "../components/layouts/LegalLayout";
import cgu from "../assets/md/cgu";

export default class extends React.Component {
  render() {
    return <LegalLayout text={cgu} />;
  }
}
