import React from "react";
// import "../../styles/creative.css";
// import "../../styles/global.css";
import "./legal.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

import Header from "../Header";
import Footer from "../Footer";

import showdown from "showdown";
export default class extends React.Component {
  constructor() {
    super();
    this.state = {
      html: "N/A"
    };
  }
  componentDidMount() {
    const converter = new showdown.Converter();
    const html = converter.makeHtml(this.props.text);
    this.setState({ html });
  }
  render() {
    return (
      <div id="top">
        <Header />
        <section>
          <div
            className="container legal-container"
            dangerouslySetInnerHTML={{ __html: this.state.html }}
          />
        </section>
        <Footer />
      </div>
    );
  }
}
